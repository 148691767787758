

























































































































import Vue from 'vue'

export default Vue.extend({
    name: 'User',

    data(){
        return {
            releaseNotes:[
                {
                    version:'3.0.00',
                    date:'Apr-26-2023',
                    notes:[
                        'Released a new UI using the Vue JS framework'
                    ]
                }
            ]
        }
    },
})
